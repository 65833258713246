$primary: #ffcd00;
$secondary: #00a3ae;
$dark: #707070;
$base-font-size: 14px;

$header-top-height: 48px;
$header-bottom-height: 21px;
$header-border-radius: 24px;
$header-total-height: $header-top-height + $header-bottom-height;
$tray-radius: 16px;

$input-border-radius: 24px;
$input-side-padding: 25px;
$header-top-color: #ffffff;
$header-bottom-color: #ededed;
$text-color: #6b645e;
$login-bg-color: #a5a498;
$card-bg-color: #f5f5f5;
$info-text-color: #a5a498;
$support-card-bg-color: #7ed0d6;
$support-card-inactive-color: #bcd8df;
$support-card-text-color: #f5f5f5;
$category-border-radius: 16px;
$product-border-radius: 8px;
$textarea-border-color: #bcd8df;
$button-border-radius: 48px;
$support-button-size: 84px;
$support-button-margin: 24px;
$gradient-height: 10%;
$body-bottom-margin: 15%;
$input-border-color: #edbf00;
$image-text-bg-opacity: 0.7;
$primary-btn-border: rgba(255, 205, 0, 1);
$fade-bg-color: #ffffffb3;

@mixin set-flex($number) {
  flex: $number;
}

@function calculateRem($size) {
  $remSize: calc($size / $base-font-size);
  @return #{$remSize}rem;
}

@mixin pixelToRem($attribute, $size) {
  #{$attribute}: calculateRem($size);
}
