/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import 'bootstrap/scss/bootstrap';
//@import '@angular/material/prebuilt-themes/indigo-pink.css';

html {
  font-size: $base-font-size;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  color: $text-color;
  line-height: 1;
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
}

.mobile-modal {
  .modal-dialog {
    position: absolute;
    top: $header-total-height;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .modal-content {
    height: 100%;
    border-radius: $tray-radius;
  }
}

.fill-space {
  @include set-flex(1);
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

.modal-content {
  box-shadow: 0 3px 32px rgba(0, 0, 0, 0.2);
  border: none;
}

.modal-backdrop {
  background-color: #ffffff;

  &.show {
    opacity: 0.8;
  }
}

.btn-outline-primary {
  color: $text-color !important;

  &:active {
    color: #212529 !important;
  }
}
